.row {
    display: flex;
    flex-wrap: wrap;
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    flex: 0 0 46.666666%;
    max-width: 46.666666%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    flex: 0 0 91.666666%;
    max-width: 91.666666%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.kutuItem {
    padding: 0px !important;
}
.kutuIci {
    width: 50%;
    display: inline-block;
}

.son {
    page-break-after: always;
}
.pdfTitle {
    text-align: center;
    padding:10px 0;
    position: relative;
}
.pdfTitle:after {
       /* width: 1px; */
       border-right: 1px solid #fff;
       position: absolute;
       content: " ";
       color: #ffffff;
       right: 0;
       top: 0;
       /* font-size: 36px; */
       height: 100%;
}