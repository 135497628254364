.menuText {
    font-size: 12px;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
.dropdownLink{
    width: 100px;
    color: #fff;
    outline: none;
    cursor: pointer;
    font-size: 13px;
    padding: 10px;
    background: orange;
    font-weight: 800;
}
.dropdownWrapper{
    padding: 10px;
}
.dropdownItem{
    width: 100%;
    display: inline-block;
}
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 9999999999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: '';
  display: block;
  font-size: 20px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 1500ms infinite linear;
  animation: spinner 1500ms infinite linear;
  border-radius: 0.5em;
box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.hizliIslem {
  font-weight: 400;
  /* animation: hello 0.5s ease-in-out infinite; */
  text-align: center;
}

@-webkit-keyframes hello {
  0% {
    font-weight: 200;
    text-shadow: none;
  }
  50% {
    font-weight: 900;
    text-shadow: 3px 3px #000000;
  }
  100% {
    font-weight: 200;
    text-shadow: none;
  }
}

@keyframes hello {
  0% {
    font-weight: 200;
    text-shadow: none;
  }
  50% {
    font-weight: 900;
    text-shadow: 3px 3px #000000;
  }
  100% {
    font-weight: 200;
    text-shadow: none;
  }
}

/* Style the tab */
.tab {
  overflow: hidden;
  background-color: #f1f1f1;
  overflow: hidden;
  box-shadow: 0px -1px 10px 1px #ababab;
}
.tablinks{
  color: #888;
  font-weight: 560;
}

.tabActive{
  background: #8d8d8d !important;
  color: #f1f1f1 !important;
}

.tablinks:hover{
  background: #8d8d8d;
}
/* Style the buttons inside the tab */
.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
  background-color: #ddd;
  
}

/* Create an active/current tablink class */
.tab button.active {
  background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}
.menuItemLink{
  text-decoration: none;
  color:#000;
}

/* chat için eklenen css */


/* Chat containers */
.container {
  border: 2px solid #77329c;
  background-color: rgb(150, 170, 241);
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  width: 1180px;
}

/* Darker chat container */
.darker {
  border-color: #ccc;
  background-color: rgb(162, 218, 166);
}

/* Clear floats */
.container::after {
  content: "";
  clear: both;
  display: table;
}

/* Style time text */
.time-right {
  float: right;
  color: red;
}

/* Style time text */
.time-left {
  float: left;
  color: #999;
}


.noteFile{
  display: flex;
  width: 100%;
}
.tree {
  font-family: 'Avenir Book', sans-serif;
  width:1000px;
  margin:0 auto;
}

.tree ul {
  padding-top: 20px; 
  position: relative;
  
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

.tree li {
  float: left; 
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;
  
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*We will use ::before and ::after to draw the connectors*/

.tree li::before, .tree li::after{
  content: '';
  position: absolute; top: 0; right: 50%;
  border-top: 1px solid #ccc;
  width: 50%; height: 20px;
}
.tree li::after{
  right: auto; left: 50%;
  border-left: 1px solid #ccc;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree li:only-child::after, .tree li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.tree li:first-child::before, .tree li:last-child::after{
  border: 0 none;
}
/*Adding back the vertical connector to the last nodes*/
.tree li:last-child::before{
  border-right: 1px solid #ccc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.tree li:first-child::after{
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul::before{
  content: '';
  position: absolute; top: 0; left: 50%;
  border-left: 1px solid #ccc;
  width: 0; height: 20px;
}

/************************************************
  * Third Level Styles
  ************************************************/

.tree ul ul ul {
  max-width:140px;
}
.tree ul ul ul li {
  float: left; 
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 0 5px 10px 5px;
  border-left: 1px solid #ccc;
  border-left:0;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  margin-left:10px;
  top:-10px;
}

/*We will use ::before and ::after to draw the connectors*/

.tree ul ul ul li:before {
  border-top: 1px solid #ccc;
  position:relative;
  top:20%;
  width:10%;
}
.tree ul ul ul li::after{
  right: auto; 
  left: -1px;
  border-left: 1px solid #ccc;
  border-bottom:1px;
  height:70px;
}

/*We need to remove left-right connectors from elements without 
any siblings*/
.tree ul ul ul li:only-child::after, .tree ul ul ul li:only-child::before {
  display: none;
}

/*Remove space from the top of single children*/
.tree ul ul ul li:only-child{ padding-top: 0;}

/*Remove left connector from first child and 
right connector from last child*/
.tree ul ul ul li:first-child::before, 
.tree ul ul ul li:last-child::after{
  border:none;
}

.tree ul ul ul li:last-child::after {
  border-bottom:1px solid #ccc;
  top:-52px;
  width:7px;
}
/*Adding back the vertical connector to the last nodes*/
.tree ul ul ul li:last-child::before{
  border-right: 0;
    border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;

}
.tree ul ul ul li:first-child::after{
  border-radius: 0 0 0 0;
  -webkit-border-radius: 0 0 0 0;
  -moz-border-radius: 0 0 0 0;
}

/*Time to add downward connectors from parents*/
.tree ul ul ul::before{
  content: '';
  position: absolute; 
  top: 0; 
  left:9px;
  border-left: 1px solid #ccc;
  width: 0; 
  height: 100;
}


/*******************************/

.tree li a{
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: 'Fira Sans', sans-serif;
  font-size: 12px;
  display: inline-block;
  
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
}

/*Time for some hover effects*/
/*We will apply the hover effect the the lineage of the element also*/
.tree li a:hover, .tree li a:hover+ul li a {
  background: #ea1519; color: #fff; border: 1px solid #ea1519;
}

.tree ul ul ul li a:hover {
  border-color: #ccc;
}
/*Connector styles on hover*/
.tree li a:hover+ul li::after, 
.tree li a:hover+ul li::before, 
.tree li a:hover+ul::before, 
.tree li a:hover+ul ul::before {
  border-color:  #fdfcfc;
}

.tree li a {
  max-width:110px;
}

.kirmizi{
  color:#fff !important;
  background-color: #fb3b3b !important;
}

.yesil{
  color:#fff !important;
  background-color: #38d238 !important;
}
.MuiDialog-paperFullWidth-410 {
    /* background-color: #0e9e9e !important; */
    width: 30% !important;
}

.MuiMenu-paper-151 {
    /* background-color: #0e9e9e !important; */
}

.AddInvoice-submit-510{
    /* background-color: #45dde4 !important; */
}

.inputInvoice {
    border-radius: 5px;
    width: 50% !important;
    padding: 20px;
    margin-top: 10px !important;
    margin-bottom: 15px;
    /* background: #45dde4 50% !important; */
    /* border: none; */
    /* outline: none; */
}
.row {
    display: flex;
    flex-wrap: wrap;
}

.col-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
}

.col-2 {
    flex: 0 0 16.666666%;
    max-width: 16.666666%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
}

.col-5 {
    flex: 0 0 46.666666%;
    max-width: 46.666666%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
}

.col-8 {
    flex: 0 0 66.666666%;
    max-width: 66.666666%;
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
}

.col-11 {
    flex: 0 0 91.666666%;
    max-width: 91.666666%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.kutuItem {
    padding: 0px !important;
}
.kutuIci {
    width: 50%;
    display: inline-block;
}

.son {
    page-break-after: always;
}
.pdfTitle {
    text-align: center;
    padding:10px 0;
    position: relative;
}
.pdfTitle:after {
       /* width: 1px; */
       border-right: 1px solid #fff;
       position: absolute;
       content: " ";
       color: #ffffff;
       right: 0;
       top: 0;
       /* font-size: 36px; */
       height: 100%;
}
