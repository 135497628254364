.MuiDialog-paperFullWidth-410 {
    /* background-color: #0e9e9e !important; */
    width: 30% !important;
}

.MuiMenu-paper-151 {
    /* background-color: #0e9e9e !important; */
}

.AddInvoice-submit-510{
    /* background-color: #45dde4 !important; */
}

.inputInvoice {
    border-radius: 5px;
    width: 50% !important;
    padding: 20px;
    margin-top: 10px !important;
    margin-bottom: 15px;
    /* background: #45dde4 50% !important; */
    /* border: none; */
    /* outline: none; */
}