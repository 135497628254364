.menuText {
    font-size: 12px;
    padding: 0 16px;
    color: rgba(0, 0, 0, 0.87);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
}
.dropdownLink{
    width: 100px;
    color: #fff;
    outline: none;
    cursor: pointer;
    font-size: 13px;
    padding: 10px;
    background: orange;
    font-weight: 800;
}
.dropdownWrapper{
    padding: 10px;
}
.dropdownItem{
    width: 100%;
    display: inline-block;
}